<template>
  <div class="container">
    <div class="d-flex justify-content-between align-items-center mb-1">
      <strong>ผู้จัดการ</strong>
      <button class="btn btn-sm btn-link" @click="addManager">เพิ่มผู้จัดการ</button>
    </div>
    <div class="card">
      <table class="table table-group table-sm table-hover mb-0">
        <thead>
          <tr>
            <th width="25%">ชื่อ</th>
            <th width="25%">รหัสผู้ใช้</th>
            <th width="25%">ใช้งานล่าสุด</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items">
            <td style="vertical-align: middle;">{{ item.users[0].firstname }}</td>
            <td style="vertical-align: middle;">{{ item.users[0].username }}</td>
            <td style="vertical-align: middle;">
              <span>{{ dateFormat(item.users[0].last_active, 'DD/MM/YYYY HH:mm:ss') }}</span>
            </td>
            <td class="text-right">
              <button class="btn btn-sm btn-link" @click="editManager(item)">แก้ไข</button>
            </td>
          </tr>
          <tr v-if="items.length == 0">
            <td colspan="4" class="small text-center">ไม่มีข้อมูล</td>
          </tr>
        </tbody>
      </table>
    </div>

    <ManagerModal title="ตั้งค่าผู้จัดการ" :data="data" :is-show="isShowManagerModal" @close="modalClosed" />
  </div>
</template>
<script>
import moment from '@/helpers/moment'

import ManagerService from '@/services/ManagerService.js'
import ManagerModal from './components/ManagerModal'

const defaultData = {
  _id: null,
  user_id: null,
  name: 'ผู้จัดการ',
  username: '',
  password: '',
  firstname: '',
  lastname: '',
  tel: '',
  line_id: ''
}

export default {
  name: 'AdminSettingManager',
  components: {
    ManagerModal
  },
  data() {
    return {
      isShowManagerModal: false,
      data: JSON.parse(JSON.stringify(defaultData)),
      Managers: []
    }
  },
  computed: {
    items() {
      return this.Managers
    }
  },
  methods: {
    loadManager() {
      ManagerService.gets().then((response)=>{
        console.log(response)
        if(response.status === 'success') {
          this.Managers = response.data
        }
      })
    },
    addManager() {
      this.data = JSON.parse(JSON.stringify(defaultData))
      this.isShowManagerModal = true
    },
    editManager(item) {
      console.log(item)
      let data = JSON.parse(JSON.stringify(defaultData))
      this.data = {
        ...data,
        _id: item._id,
        user_id: item.users[0]._id,
        name: item.name,
        username: item.users[0].username,
        password: '',
        firstname: item.users[0].firstname,
        lastname: item.users[0].lastname,
        tel: item.users[0].tel,
        line_id: item.users[0].line_id
      }

      this.isShowManagerModal = true
    },
    modalClosed(needReload) {
      this.isShowManagerModal = false
      console.log('needReload', needReload)
      if(needReload) {
        this.loadManager()
      }
    },
    dateFormat(date, format='YYYY-MM-DD') {
      if(date) {
        return moment(date).format(format)
      }else{
        return '-'
      }
    }
  },
  mounted() {
    this.loadManager()
  }
}
</script>
